import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import './index.scss';

const SubmitSuccessPopUp = ({ isModalOpen, title, content, handleOk, handleCancel, icon, confirmText = 'Done' }) => {
    const { t } = useTranslation();
    return (
        <>
            <Modal
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer=""
                centered
                width={1000}
                classNames={{ body: 'custom-modal-body' }}
            >
                <div className="modal-box">
                    <p>
                        {(() => {
                            switch (icon) {
                                case 'submit-success':
                                    return (<>
                                        <svg width="158" height="159" viewBox="0 0 158 159" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M78.9994 13.9062C42.6984 13.9062 13.166 43.4387 13.166 79.7396C13.166 116.04 42.6984 145.573 78.9994 145.573C115.3 145.573 144.833 116.04 144.833 79.7396C144.833 43.4387 115.3 13.9062 78.9994 13.9062Z" fill="#007F50" fill-opacity="0.23" />
                                            <path d="M113.438 56.526C111.278 54.2339 107.678 54.1329 105.397 56.3122L69.0833 90.9749L51.7627 73.0758C49.569 70.8104 45.9722 70.7608 43.7196 72.969C41.4689 75.1751 41.4215 78.7991 43.6133 81.0645L64.8494 103.009C65.9623 104.16 67.4413 104.74 68.9241 104.74C70.331 104.74 71.738 104.217 72.8396 103.17L113.226 64.6198C115.505 62.4441 115.601 58.82 113.438 56.526Z" fill="#007F50" />
                                        </svg></>)
                                case 'submit-error':
                                    return (<>
                                        <svg width="158" height="159" viewBox="0 0 158 159" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M54.6042 30.2101C61.6609 21.2468 69.1363 15.5527 78.9992 15.5527C88.8621 15.5527 96.3375 21.2468 103.394 30.2101C110.334 39.0253 117.689 52.0657 127.099 68.7503L129.966 73.8328C137.745 87.6247 143.889 98.5177 147.116 107.261C150.414 116.196 151.197 124.309 146.208 131.465C141.367 138.409 133.29 141.251 123.102 142.592C112.953 143.928 99.3053 143.928 81.7958 143.928H76.2026C58.6931 143.928 45.0451 143.928 34.8964 142.592C24.7086 141.251 16.6313 138.409 11.79 131.465C6.80111 124.309 7.58455 116.196 10.8825 107.261C14.1097 98.5178 20.2535 87.6248 28.0324 73.833L30.8989 68.7505C40.3091 52.0659 47.6639 39.0254 54.6042 30.2101ZM62.3631 36.3187C55.9508 44.4633 48.9599 56.8294 39.2686 74.0123L36.8745 78.2571C28.8019 92.5701 23.0652 102.773 20.1466 110.68C17.2655 118.486 17.6649 122.625 19.8908 125.818C22.2643 129.222 26.7193 131.555 36.1852 132.801C45.6125 134.042 58.6082 134.053 76.6051 134.053H81.3933C99.3902 134.053 112.386 134.042 121.813 132.801C131.279 131.555 135.734 129.222 138.108 125.818C140.333 122.625 140.733 118.486 137.852 110.68C134.933 102.773 129.197 92.5701 121.124 78.2571L118.73 74.0123C109.038 56.8294 102.048 44.4633 95.6353 36.3186C89.2827 28.2497 84.3487 25.4277 78.9992 25.4277C73.6497 25.4277 68.7158 28.2497 62.3631 36.3187Z" fill="#E3401E" />
                                            <path d="M79.0013 48.4688C81.7282 48.4688 83.9388 50.6793 83.9388 53.4062V86.3229C83.9388 89.0498 81.7282 91.2604 79.0013 91.2604C76.2744 91.2604 74.0638 89.0498 74.0638 86.3229V53.4062C74.0638 50.6793 76.2744 48.4688 79.0013 48.4688Z" fill="#E3401E" />
                                            <path d="M79.0013 112.656C82.6372 112.656 85.5846 109.709 85.5846 106.073C85.5846 102.437 82.6372 99.4896 79.0013 99.4896C75.3654 99.4896 72.418 102.437 72.418 106.073C72.418 109.709 75.3654 112.656 79.0013 112.656Z" fill="#E3401E" />
                                        </svg></>)
                                default:
                                    return <i className={`${icon ? icon : 'icon-history'} icon-box`}></i> 
                            }
                        })()}
                    </p>
                    <div className="text-title">{title}</div>
                    <p className="text-center" dangerouslySetInnerHTML={{ __html: content }}></p>
                    <div className="custom-modal-body confirm-btn">
                        <button
                            type="button"
                            class="nextBtn"
                            onClick={handleOk}
                        >
                            {t(confirmText)}
                        </button>
                    </div>
                </div>

            </Modal>
        </>
    )
}
export default SubmitSuccessPopUp