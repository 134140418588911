import './index.scss';
import DOMPurify from 'dompurify';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

export default KYC; function KYC({ Template, IsShowNotes }) {
    const history = useHistory();
    const { t } = useTranslation();
    function goDetail(url) {
        history.push({
            pathname: url
        });
    }

    useEffect(() => {
        const residency = document.getElementById('update-profile-residency');
        if (residency !== null) {
            residency.innerText = t('Please update your profile');
        }

        const citizen = document.getElementById('update-profile-citizen');
        if (citizen !== null) {
            citizen.innerText = t('Please update your profile');
        }

        const usState = document.getElementById('update-profile-us-state');
        if (usState !== null) {
            usState.innerText = t('Please update your profile');
        }

        const usTaxResidencyYes = document.getElementById('us-tax-residency-yes');
        if (usTaxResidencyYes !== null) {
            usTaxResidencyYes.innerText = t('Yes');
        }

        const usTaxResidencyNo = document.getElementById('us-tax-residency-no');
        if (usTaxResidencyNo !== null) {
            usTaxResidencyNo.innerText = t('No');
        }

    }, [window.localStorage.lang])

    const pageBreak = Template.split('#PAGE_BREAK:');
    const index = pageBreak.findIndex(item => item.includes('KYC#')) - 1;

    let template = pageBreak[index].replace(pageBreak[index].split('#')[0].trim() + '#', '');
    if (template.includes('</head>')) {
        template = template.split('</head>')[1];
    }
    const kyc = DOMPurify.sanitize('<html>' + template + '</html>');
    return (
        <>
            <div id="kyc-div">
                <div className='row stepper-title'>
                    <p>
                        {t('KYC')}
                    </p>
                    <button className='update-profile-button' onClick={() => { goDetail('/profile') }}>{t('UPDATE PROFILE')}</button>
                    {
                        IsShowNotes ? (<>
                            <p className='update-profile-notes'>
                                {t('Missing Details')}
                            </p>
                        </>) : null
                    }

                </div>
                <div className='row stepper-p kyc-content'>
                    <div dangerouslySetInnerHTML={{ __html: kyc }} />
                </div>
            </div>
        </>
    );
}