import './index.scss';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default ExitBallot; function ExitBallot({ Template, VotingResult, handleExitBallotSave }) {
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);
    }, []);

    useEffect(() => {
        const wrapper = document.getElementById('exit-ballot-div');
        wrapper.addEventListener('click', (event) => {
            const node = event.target;
            if (node.nodeName === 'INPUT') {
                let result = [];
                [].forEach.call(wrapper.querySelectorAll('input'), function (element) {
                    if (element.checked) {
                        if (element.type === 'radio') {
                            let options = {
                                optionSequence: element.name.split('_')[2],
                                purchaserID: element.name.split('_')[0],
                            }

                            if (element.value.split('_')[1] === "Yes") {
                                options.yesVoteUnit = element.value.split('_')[2];
                                options.noVoteUnit = 0;
                                options.isManual = false;
                                options.offer = element.value.split('_')[0]
                            }
                            else if (element.value.split('_')[1] === "No") {
                                options.noVoteUnit = element.value.split('_')[2];
                                options.yesVoteUnit = 0;
                                options.isManual = false;
                                options.offer = element.value.split('_')[0]
                            }
                            else if (element.value.split('_')[1] === "Manual") {
                                options.noVoteUnit = 0;
                                options.yesVoteUnit = 0;
                                options.isManual = true;
                                options.offer = element.value.split('_')[0]
                            }
                            else {
                                options.noVoteUnit = 0;
                                options.yesVoteUnit = 0;
                                options.isManual = false;
                                options.offer = element.value.split('_')[0]
                            }
                            result.push(options);
                        }
                    }
                });

                if (result.length === (Array.from(new Set(Array.from(wrapper.querySelectorAll('input')).map(radio => radio.name))).length)) {
                    handleExitBallotSave(result);
                }
            }
            else {
                return;
            }
        });
    });

    useEffect(() => {
        if (Template !== '') {
            const wrapper = document.getElementById('exit-ballot-div');
            const radioButtons = wrapper.querySelectorAll('input');
            VotingResult.forEach((result) => {
                radioButtons.forEach((radio) => {
                    let answer = '';
                    let totalUnit = 0;
                    if (result.yesVoteUnit > 0 && result.noVoteUnit === 0) {
                        answer = 'Yes';
                        totalUnit = result.yesVoteUnit;
                    }
                    else if (result.yesVoteUnit === 0 && result.noVoteUnit > 0) {
                        answer = 'No'
                        totalUnit = result.noVoteUnit;
                    }
                    else {
                        answer = 'Manual'
                        totalUnit = result.totalUnit;
                    }

                    if (radio.value === (result.offer + '_' + answer + '_' + totalUnit)) {
                        radio.checked = true;
                    }
                });
            });
        }
    }, [Template, VotingResult]);

    useEffect(() => {
        const wrapper = document.getElementById('exit-ballot-div');
        [].forEach.call(wrapper.querySelectorAll('a'), function (element) {
            element.innerText = t('VIEW OFFER LETTER');
        });
    }, [window.localStorage.lang])


    const pageBreak = Template.split('#PAGE_BREAK:');
    const index = pageBreak.findIndex(item => item.includes('EXIT BALLOT#')) - 1;
    const { t } = useTranslation();


    let template = pageBreak[index].replace(pageBreak[index].split('#')[0].trim() + '#', '');
    if (template.includes('</head>')) {
        template = template.split('</head>')[1];
    }
    const exitBallot = '<html>' + template + '</html>';
    return (
        <>
            <div id="exit-ballot-div">
                <div className='row stepper-title'>
                    <p>
                        {t('EXIT BALLOT')}
                    </p>
                </div>
                <div className='row stepper-p exit-ballot-content'>
                    <div dangerouslySetInnerHTML={{ __html: exitBallot }} />
                </div>
            </div>
        </>
    );
}