import './index.scss'
import { useState, useEffect } from 'react'
import { get } from '../../../util/request';
import { userInfo } from '../../../redux/slice';
import { useSelector } from 'react-redux';
import { Modal, CloseButton } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

export default ExitBanner; function ExitBanner({ isMissingProfileBannerOpen, isUpdateProfileButtonVisible }) {
    const { t } = useTranslation();
    const history = useHistory();
    const user = useSelector(userInfo);
    const [daysRemaining, setDaysRemaining] = useState(0);
    const [exitProcessHeader, setExitProcessHeader] = useState([]);
    const [isBannerModalOpen, setIsBannerModalOpen] = useState(true);
    const [hasMissingProfileInfo, setHasMissingProfileInfo] = useState(false);

    function goDetail(url, recId) {
        history.push({
            pathname: url,
            state: { recId: recId }
        });
        sessionStorage.setItem('recId', recId);
    }

    const goToProfile = () => {
        history.push({
            pathname: '/profile'
        });
    }

    const MissingProfileInfo = ({ text }) => {
        return (
            <div className="pending-box-missing">
                <div className="left-icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.7491 9.70957V9.00497C18.7491 5.13623 15.7274 2 12 2C8.27256 2 5.25087 5.13623 5.25087 9.00497V9.70957C5.25087 10.5552 5.00972 11.3818 4.5578 12.0854L3.45036 13.8095C2.43882 15.3843 3.21105 17.5249 4.97036 18.0229C9.57274 19.3257 14.4273 19.3257 19.0296 18.0229C20.789 17.5249 21.5612 15.3843 20.5496 13.8095L19.4422 12.0854C18.9903 11.3818 18.7491 10.5552 18.7491 9.70957Z" stroke="white" stroke-width="1.5" />
                        <path d="M7.5 19C8.15503 20.7478 9.92246 22 12 22C14.0775 22 15.845 20.7478 16.5 19" stroke="white" stroke-width="1.5" stroke-linecap="round" />
                    </svg>
                </div>
                <div className="right-text">{text}</div>
                <div className='right-button'>
                    {
                        isUpdateProfileButtonVisible ? <input type='button' value={t('UPDATE PROFILE')} className='update-profile-button' onClick={goToProfile}/> : ""
                    }
                </div>
            </div>
        )
    }


    const getExitProcessHeader = async () => {
        let params = {
            accessToken: user.accessToken,
            userId: user.userId,
            accountNum: user.clientID
        }

        const data = await get('home/exitProjects', params);

        setExitProcessHeader(data);

        if (data != null && data.length > 0) {
            const votingDeadline = new Date(data[0].votingDeadline);
            const currentDate = new Date();
            let daysRemaining = Math.floor((Date.UTC(votingDeadline.getFullYear(), votingDeadline.getMonth(), votingDeadline.getDate()) -
                Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())) / (1000 * 60 * 60 * 24));

            if (daysRemaining < 0) {
                daysRemaining = 0;
            }
            setDaysRemaining(daysRemaining);
            if (data[0].taxFilingOption === '' || data[0].citizenship === '' || data[0].residencyID === '' || data[0].usaResident === '') {
                setHasMissingProfileInfo(true);
            }
        }
    }
    const handleClose = () => {
        setIsBannerModalOpen(false);
        localStorage.setItem('banner_status', 'hidden');
    };

    const handleVoteNow = () => {
        setIsBannerModalOpen(false);
        localStorage.setItem('banner_status', 'hidden');
        let data = exitProcessHeader[0];
        goDetail('/exitvoting', data.recid);
    };

    useEffect(() => {
        getExitProcessHeader();
        if (localStorage.getItem('banner_status') === 'hidden') {
            setIsBannerModalOpen(false);
        }
    }, []);

    return (user.sfUserType === 'CL' ? <> 
        {
            exitProcessHeader != null && exitProcessHeader.length > 0 ? (<>
                <div className='exit-banner'>
                    <div className='exit-modal'>
                        <Modal
                            show={isBannerModalOpen}
                            onHide={handleClose}
                            backdrop="static"
                            centered
                            size='xl'
                            className='vote-now-modal'
                            contentClassName='modal-style'
                        >
                            <Modal.Body>
                                <div className='exit-banner-container-modal row'>
                                    <div className='col-md-6 modal-vote-col'>
                                        <svg className='modal-bubble-speech' width="675" height="400" viewBox="0 0 675 400" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M674.317 206.543L594.371 150.634C587.513 92.825 565.382 34.4915 524.415 -19.4696C468.027 -93.6694 381.116 -138.908 288.289 -142.127C2.08125 -152.087 -163.96 161.389 5.43638 395.015C54.3485 462.489 163.599 520.149 246.582 522.825C433.76 528.922 569.324 396.551 592.989 238.918L674.317 206.543Z" fill="#40C2CC" />
                                        </svg>
                                        <svg className='modal-v-svg' width="77" height="80" viewBox="0 0 77 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.985352 0.22876H17.1671L38.7428 59.9689L60.9799 0.22876H76.144L44.1876 79.3056H32.0258L0.985352 0.22876Z" fill="white" />
                                        </svg>
                                        <svg className='modal-o-svg' width="85" height="84" viewBox="0 0 85 84" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M42.1456 0.0593243C66.8253 -0.39865 84.2792 16.1393 84.2792 41.5314C84.2792 66.9235 66.8253 82.7491 42.1456 83.2071C17.7712 83.2071 0.368164 66.6691 0.368164 41.9894C0.368164 17.3097 17.8221 0.110202 42.1456 0.110202M42.2473 70.4855C58.6835 70.4855 69.5223 57.9676 69.5223 41.0734C69.5223 25.2988 58.6835 12.7808 42.2473 12.7808C25.8112 12.7808 15.1251 25.2988 15.1251 41.0734C15.1251 57.9676 25.9638 70.4855 42.2473 70.4855Z" fill="white" />
                                        </svg>
                                        <svg className='modal-check-svg' width="66" height="86" viewBox="0 0 66 86" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M40.5272 35.1658C36.4622 42.6451 30.6839 57.3377 28.8027 64.5511L11.9381 36.0965L2.16211 49.2601L29.3065 82.5014C29.3065 82.5014 37.6044 65.648 50.0009 47.2324C53.2931 42.3459 61.4902 33.9358 64.245 30.2793V3.48682C63.1027 4.31785 51.9829 13.9246 40.4936 35.1325" fill="#FFC81C" />
                                            <path d="M40.5272 35.1658C36.4622 42.6451 30.6839 57.3377 28.8026 64.5511L11.9381 36.0965L2.16211 49.2601L29.3065 82.5014C29.3065 82.5014 37.6044 65.648 50.0009 47.2324C53.2931 42.3459 61.4902 33.9358 64.245 30.2793V3.48682C63.1027 4.31785 51.9829 13.9246 40.4936 35.1325" stroke="#40C2CC" stroke-width="2.82195" />
                                        </svg>
                                        <svg className='modal-t-svg' width="64" height="80" viewBox="0 0 64 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M25.1339 12.9503H0.861328V0.22876H63.4511V12.9503H39.2294V79.3056H25.1339V12.9503Z" fill="white" />
                                        </svg>
                                        <svg className='modal-e-svg' width="56" height="80" viewBox="0 0 56 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.674805 0.22876H53.0874V12.9503H14.7702V32.3887H51.0519V45.1102H14.7702V66.5841H55.072V79.3056H0.674805V0.22876Z" fill="white" />
                                        </svg>
                                        <svg className='modal-e-mark-svg' width="32" height="132" viewBox="0 0 32 132" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16.3478 103.323C24.2447 103.323 31.054 109.376 31.054 117.273C31.054 125.17 24.4338 131.601 16.3478 131.601C8.26176 131.601 1.64162 125.548 1.64162 117.651C1.64162 109.755 8.26176 103.323 16.3478 103.323ZM27.3656 89.7049H5.32998L0.743164 0.380371H31.9997L27.3656 89.7049Z" fill="white" />
                                        </svg>
                                        <p className={window.localStorage.lang === 'jp' ? 'modal-for-exit-offer-jp modal-for-exit-offer' : 'modal-for-exit-offer'}>
                                            {t('For Exit Offer')}
                                        </p>
                                        <div className={window.localStorage.lang === 'jp' ? 'modal-remaining-days-col modal-remaining-days-col-jp' : 'modal-remaining-days-col'}>
                                            <p>{daysRemaining > 1 ? t('Days Remaining').replace('{days}', daysRemaining) : t('Less than 1 day remaining')}</p>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='close-button-modal' onClick={handleClose}>
                                            <CloseButton variant='white' />
                                        </div>
                                        <input type='button' value={t('Vote Now')} className='modal-vote-button' onClick={handleVoteNow} />
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                    {
                        !isBannerModalOpen ? (<>
                            <div className='exit-banner-container row'>
                                <div className='col-md-5 vote-col'>
                                    <svg className='speech-bubble' width="478" height="150" viewBox="0 0 478 150" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M477.257 72.899L396.675 45.2462C385.268 5.12793 362.998 -33.63 327.898 -67.1589C279.593 -113.257 212.98 -135.406 146.946 -127.158C-56.6575 -101.751 -138.577 138.84 7.75087 284.908C50.0039 327.096 133.83 355.5 192.838 347.983C325.946 331.073 406.84 222.057 405.708 107.859L477.257 72.899Z" fill="#40C2CC" />
                                    </svg>
                                    <svg className='v-svg' width="54" height="57" viewBox="0 0 54 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.40625 0.0307617H11.8747L27.1661 42.3704L42.9262 0.0307617H53.6735L31.025 56.0749H22.4056L0.40625 0.0307617Z" fill="white" />
                                    </svg>
                                    <svg className='o-svg' width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M30.5288 0.201886C48.0201 -0.122694 60.3902 11.5982 60.3902 29.5944C60.3902 47.5906 48.0201 58.8066 30.5288 59.1312C13.254 59.1312 0.919922 47.4103 0.919922 29.919C0.919922 12.4277 13.29 0.237945 30.5288 0.237945M30.601 50.1151C42.2498 50.1151 49.9315 41.2432 49.9315 29.2698C49.9315 18.0899 42.2498 9.218 30.601 9.218C18.9521 9.218 11.3786 18.0899 11.3786 29.2698C11.3786 41.2432 19.0603 50.1151 30.601 50.1151Z" fill="white" />
                                    </svg>
                                    <svg className='check-svg' width="47" height="60" viewBox="0 0 47 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M28.5557 24.4518C25.6748 29.7526 21.5795 40.1658 20.2462 45.2781L8.2938 25.1115L1.36523 34.4409L20.6033 58C20.6033 58 26.4843 46.0555 35.27 33.0038C37.6033 29.5406 43.4128 23.5801 45.3652 20.9886V2C44.5557 2.58898 36.6748 9.39756 28.5319 24.4283" fill="#FFC81C" />
                                        <path d="M28.5557 24.4518C25.6748 29.7526 21.5795 40.1658 20.2462 45.2781L8.2938 25.1115L1.36523 34.4409L20.6033 58C20.6033 58 26.4843 46.0555 35.27 33.0038C37.6033 29.5406 43.4129 23.5801 45.3652 20.9886V2C44.5557 2.58898 36.6748 9.39756 28.5319 24.4283" stroke="#40C2CC" stroke-width="2" />
                                    </svg>
                                    <svg className='t-svg' width="46" height="57" viewBox="0 0 46 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18.0953 9.04688H0.892578V0.0307617H45.2519V9.04688H28.0852V56.0749H18.0953V9.04688Z" fill="white" />
                                    </svg>
                                    <svg className='e-svg' width="39" height="57" viewBox="0 0 39 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.0410156 0.0307617H37.1874V9.04688H10.0309V22.8235H35.7448V31.8396H10.0309V47.0588H38.5939V56.0749H0.0410156V0.0307617Z" fill="white" />
                                    </svg>
                                    <svg className='e-mark-svg' width="23" height="103" viewBox="0 0 23 103" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.0595 73.0246C16.6562 73.0246 21.4822 77.3143 21.4822 82.9111C21.4822 88.5078 16.7903 93.0657 11.0595 93.0657C5.32866 93.0657 0.636765 88.776 0.636765 83.1792C0.636765 77.5824 5.32866 73.0246 11.0595 73.0246ZM18.8681 63.3727H3.25081L0 0.0656738H22.1524L18.8681 63.3727Z" fill="white" />
                                    </svg>
                                    <p className={window.localStorage.lang === 'jp' ? 'for-exit-offer-jp' : 'for-exit-offer'}>
                                        {t('For Exit Offer')}
                                    </p>
                                </div>
                                <div className='col-md-6 remaining-days-col'>
                                    <p>{daysRemaining > 1 ? t('Days Remaining').replace('{days}', daysRemaining) : t('Less than 1 day remaining')}</p>
                                </div>
                                <div className='col-md-1 vote-button-col'>
                                    <input type='button' value={t('Vote Now')} className='exit-banner-vote' onClick={handleVoteNow} />
                                </div>
                            </div>
                            {
                                isMissingProfileBannerOpen ? (<>

                                    {
                                        hasMissingProfileInfo ? (<>
                                            <MissingProfileInfo text={t('Missing profile')}></MissingProfileInfo>
                                        </>) : null
                                    }
                                </>) : ""
                            }
                        </>) : ""
                    }
                </div>
            </>) : ""
        }
    </> : null)
}