import './index.scss';
import DOMPurify from 'dompurify';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

export default NFC; function NFC({ Template }) {
    const history = useHistory();
    const { t } = useTranslation();
    function goDetail(url) {
        history.push({
            pathname: url
        });
    }

    const pageBreak = Template.split('#PAGE_BREAK:');
    const index = pageBreak.findIndex(item => item.includes('NFC#')) - 1;

    let template = pageBreak[index].replace(pageBreak[index].split('#')[0].trim() + '#', '');
    if (template.includes('</head>')) {
        template = template.split('</head>')[1];
    }

    const nfc = DOMPurify.sanitize('<html>' + template + '</html>');
    return (
        <>
            <div id="nfc-div">
                <div className='row stepper-title nfc-title'>
                    <p>
                        {t('NFC')}
                    </p>
                    <button className='update-profile-button' onClick={() => { goDetail('/profile') }}>{t('UPDATE PROFILE')}</button>
                </div>
                <div className='row stepper-p nfc-content'>
                    <div dangerouslySetInnerHTML={{ __html: nfc }} />
                </div>
            </div>
        </>
    );
}