import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { post, get, put, upload } from '../../util/request';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { userInfo, updateUserInfoFun } from '../../redux/slice';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import Updata from '../../components/page/updata/index';
import './index.scss';
import forge from 'node-forge';
import { Form, message, Switch } from 'antd';
import phoneHeaderOption from '../../components/dialCode.json';
import { useTranslation } from 'react-i18next';
import { REGEX } from '../../constants';
import SubmitSuccessPopUp from '../../components/page/submitSuccessPopUp';
import Loading from '../../components/common/loading';
import ExitBanner from '../../components/page/exitBanner/index.js';
import Dropdown from 'react-bootstrap/Dropdown'

const FormItem = Form.Item;
const Profile = () => {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();

  const user = useSelector(userInfo);
  const formRef = useRef();
  const resetFormRef = useRef();
  const [isButton, setButton] = useState(true);
  const [detailData, setDetailData] = useState({
    clientId: null,
  });

  const [isSearchable, setIsSearchable] = useState(false);
  const [citiZenOption, setCitiZenOption] = useState([]);
  const [residencyOption, setResidencyOption] = useState([]);
  const [provinceOption, setProvinceOption] = useState([]);
  const [countryOption, setCountryOption] = useState([]);
  const [country, setCountry] = useState(null);
  const [province, setProvince] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [loading, setLoading] = useState(false);
  const [statesOption, setStatesOption] = useState([]);
  const [taxFilingOption, setTaxFilingOption] = useState();
  const [taxEngagementDisclaimer, setTaxEngagementDisclaimer] = useState({});
  const [primaryLanguage, setPrimaryLanguage] = useState('');
  const [disclaimer, setDisclaimer] = useState('');
  const [residencyDetails, setResidencyDetails] = useState({ citizenship: '', residency: '', usResidency: '' });
  const [residencyStatus, setResidencyStatus] = useState(false);
  const [postalCodeOption, setPostalCodeOption] = useState([]);
  const [isMissinInfoOpen, setIsMissingInfoOpen] = useState(false);
  const typingTimeout = useRef(null);
  const [isPostalMenuOpen, setIsPostalMenuOpen] = useState(false);
  const [postalCode, setPostalCode] = useState('');


  //#region TAX Section
  const handleChangeTax = async (name, e) => {
    formRef?.current?.setFieldValue(name, e.target.value);
    if (name === 'taxFilingOption') {
      setTaxFilingOption(e.target.value);
    }
  };

  const formatDate = (value) =>
    new Intl.DateTimeFormat('fr-CA', {
      year: 'numeric', month: '2-digit', day: '2-digit'

    }).format(value).replace(/-/g, '/');

  useEffect(() => {
    if (taxEngagementDisclaimer.length > 0 && taxFilingOption !== '') {
      const taxDisclaimer = taxEngagementDisclaimer.filter(x => x.type === taxFilingOption);
      if (taxDisclaimer.length > 0) {
        switch (window.localStorage.lang) {
          case "Traditional Chinese":
          case "zh_HK":
            if (taxDisclaimer[0].contentTC !== '') {
              setDisclaimer(taxDisclaimer[0].contenttc);
            }
            else {
              setDisclaimer(taxDisclaimer[0].contenten);
            }
            break;
          case "Simplified Chinese":
          case "zh_CN":
            if (taxDisclaimer[0].contentSC !== '') {
              setDisclaimer(taxDisclaimer[0].contentsc);
            }
            else {
              setDisclaimer(taxDisclaimer[0].contenten);
            }
            break;
          case "Japanese":
          case "jp":
            if (taxDisclaimer[0].contentJA !== '') {
              setDisclaimer(taxDisclaimer[0].contentja);
            }
            else {
              setDisclaimer(taxDisclaimer[0].contenten);
            }
            break;
          default:
            setDisclaimer(taxDisclaimer[0].contenten);
            break;
        }
      }
    }
  }, [taxEngagementDisclaimer, taxFilingOption, window.localStorage.lang]);
  //#endregion

  //#region US Residency
  const usTaxResidencyOption = [
    { value: 'Resident', label: t('Resident') },
    { value: 'Non-Resident', label: t('Non-Resident') }
  ];

  useEffect(() => {
    if (residencyDetails.usResidency === 'Resident') {
      setResidencyStatus(true);
    }
    else {
      setResidencyStatus(false);
      formRef?.current?.setFieldValue('usaResidentState', '');
    }
  }, [residencyDetails]);
  //#endregion

  //#region Postal
  const handlePostalChange = (e) => {
    setPostalCode(e.target.value);
    clearTimeout(typingTimeout.current)
    typingTimeout.current = setTimeout(() => {
      getPostalCodeOption(province, country, e.target.value);
      formRef?.current?.setFieldValue('zipCode', e.target.value);
    }, 1000);
  }

  const handlePostalClick = (value) => {
    setPostalCode(value);
    formRef?.current?.setFieldValue('zipCode', value);
  }
  //#endregion

  const CustomInput = ({ value, onChange, ref, list }) => {
    return (
      <>
        <input
          list={list}
          className="form-control custom-input"
          //style={{ width: '370px' }}
          maxLength={255}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          ref={ref}
        />
      </>
    )
  }


  const CustomInputPwd = ({ value, onChange }) => {
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };
    const inputType = showPassword ? 'text' : 'password';
    return (

      <div className='password-input'>
        <input
          className="form-control custom-input-pwd"
          //style={{ width: '100%' }}
          maxLength={20}
          placeholder={t('Enter new password')}
          value={value}
          type={inputType}
          autocomplete={"off"}
          onChange={(e) => onChange(e.target.value)}
        />
        <button className="change-btn f-c-title" onClick={togglePasswordVisibility}>
          <i className={`${showPassword ? 'icon-eye' : 'icon-close-eye'} eye`}></i>
        </button>
      </div >
    )
  }
  const [error, setError] = useState('');
  const CustomPhoneNumber = ({ value, onChange, inputRequired, maxLength }) => {
    let option = [];
    let header = value ? value.split(' ')[0] : '';
    let last = value ? value.split(' ')[1] : '';
    // setPhoneNumber(last)
    phoneHeaderOption.map((item) => {
      option.push({ value: item.dial, label: item.dial + '(' + item.en + ')' });
      if (header == item.dial) {
        header = { value: item.dial, label: item.dial + '(' + item.en + ')' };
      }
    });
    const validateInput = (val) => {
      if (!val.trim()) {
        setError(t('This field is required'));
      } else {
        setError('');
      }
    };
    const handleChange = (e) => {
      onChange(header.value + ' ' + e.target.value);
      last = e.target.value;
      if (inputRequired) {
        validateInput(e.target.value);
      }
      // onChange(e.target.value);
      // setPhoneNumber(e.target.value);
    };
    const phoneCustomStyles = {
      control: (base) => ({
        ...base,
        height: 40,
        width: '100%',
        float: 'left',
        border: '1px solid #003448',
        paddingLeft: '10px',
        fontFamily: 'Avenir LT Std Medium'
      }),
    };
    return (
      <div className="select-box">
        <Select
          value={header}
          onChange={(e) => {
            onChange(e.value + ' ' + last);
            header = e;
          }}
          className='phone-select'
          isSearchable={true}
          options={option}
          styles={phoneCustomStyles}
          maxLength={15}
          theme={(theme) => ({
            ...theme,
            borderRadius: 5,
            colors: {
              ...theme.colors,
              primary25: '#F5F7FA',
              primary: '#003448',
            },
            // styles: { phoneStyles }
          })}
        />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <input
            className="form-control"
            style={{ float: 'left', marginLeft: "10px" }}
            value={last}
            // onChange={(e) => {
            //   onChange(header.value + ' ' + e.target.value);
            //   last = e.target.value;
            // }}
            onChange={handleChange}
            maxLength={maxLength}
          />
          {inputRequired && (error && <p className="error-message">{error}</p>)}
        </div>


      </div>
    );
  };

  const CustomSelect = ({ value, onChange, option, name }) => {
    let valueOption = value && value != '' ? { value: value, label: value } : null;
    if (name == 'country') {
      valueOption = {
        ...valueOption,
        label: option?.find((item) => item?.countryregionid == value)?.label || value
      }
    } else if (name == 'state') {
      valueOption = {
        ...valueOption,
        label: option?.find((item) => item?.stateId == value)?.province || value
      }
    }
    return (
      <Select
        value={valueOption}
        onChange={(e) => {
          if (name == 'country') {
            setCountry(e.countryregionid);
            formRef?.current?.setFieldValue('state', '');
            getPostalCodeOption(province, e.countryregionid, '');
          }
          else if (name === 'language') {
            setPrimaryLanguage(e.value);
          }
          else if (name === 'residency') {
            setResidencyDetails(prevData => ({
              ...prevData,
              residency: e.value
            }));
          }
          else if (name === 'citizenship') {
            setResidencyDetails(prevData => ({
              ...prevData,
              citizenship: e.value
            }));
          }
          else if (name === 'state') {
            getPostalCodeOption(e.value, country, '');
          }
          else if (name === 'usTaxResidency') {
            setResidencyDetails(prevData => ({
              ...prevData,
              usResidency: e.value
            }));
          }
          onChange(e.value);
        }}


        className='custom-select'
        isSearchable={true}
        options={option}
        styles={customStyles}
        maxLength={50}
        theme={(theme) => ({
          ...theme,
          borderRadius: 5,
          colors: {
            ...theme.colors,
            primary25: '#F5F7FA',
            primary: '#003448',
          },
          // styles: { phoneStyles }
        })}
      />
    );
  };
  const CustomSubmitButton = ({ form, onClick, text, disabled }) => {
    const handleClick = () => {
      formRef?.current
        ?.validateFields()
        .then(() => {
          if (!error) {
            submitProfile(formRef.current?.getFieldsValue());
          } else {
            const errorList = document.querySelectorAll(".error-message")
            errorList[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' })
          }
        })
        .catch((err) => {
          const errorList = document.querySelectorAll(".ant-form-item-has-error")
          errorList[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' })
          console.log('errro');
        });
    };

    return (
      <div
        className="btn-box update-password"
        style={{ display: 'flex', justifyContent: 'end', marginRight: '80px' }}
      >
        <button html-type='submit' type="button" disabled={disabled} onClick={handleClick} className={`${disabled ? 'disabled-btn' : ""} ApplyBtn btn btn-outline-next`}>
          {text}
        </button>
      </div>
    );
  };

  const CustomResetSubmitButton = ({ form, onClick, text }) => {
    const handleClick = () => {
      resetFormRef?.current
        ?.validateFields()
        .then(() => {
          submitChangePassword(resetFormRef.current?.getFieldsValue());
        })
        .catch(() => {
          console.log('errro');
        });
    };

    return (
      <div
        className="btn-box update-password"
        style={{ display: 'flex', justifyContent: 'end', marginRight: '80px' }}
      >
        <button type="button" onClick={handleClick} className="ApplyBtn btn btn-outline-next">
          {text}
        </button>
      </div>
    );
  };
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 40,
      width: '100%',
      cursor: 'pointer',
      border: '1px solid #003448',
      paddingLeft: '10px',
      fontFamily: 'Avenir LT Std Medium'
    }),
  };
  const [key, setkey] = useState('');

  const encode = (value) => {
    var pubencryptKey = key;
    let rsaPublicKey = forge.pki.publicKeyFromPem(
      `-----BEGIN PUBLIC KEY-----${pubencryptKey}-----END PUBLIC KEY-----`
    );
    let encrypted = rsaPublicKey.encrypt(value, 'RSA-OAEP', {
      md: forge.md.sha256.create(),
      mgf1: {
        md: forge.md.sha1.create(),
      },
    });
    return forge.util.encode64(encrypted);
  };

  const getkey = async () => {
    let deviceId = {
      deviceId: 'Web',
    };
    const data = await get('/auth/key', deviceId);
    if (!data) {
      return;
    }
    setkey(data.message);
  };
  const [isCheck, setisCheck] = useState(false);

  const checkOn = () => {
    setisCheck(true);
  };

  const checkOff = () => {
    setisCheck(false);
  };

  const init = async () => {
    let setting = await getSetting();
    let params = {
      offset: 'DESC',
      deviceId: null,
      deviceModel: ' WEB',
      deviceToken: null,
      entry: 'WEB',
      isChina: false,
      accessToken: user.accessToken,
      userId: user.userId,
    };
    const data = await get('/appUser/profile', params);
    if (data) {
      var day = data.dob.slice(8, 10)
      var year = data.dob.slice(0, 4)
      var month = data.dob.slice(5, 7)
      data.dob = year + ' / ' + month + ' / ' + day
      let obj = {
        ...data,
        MARKET: setting.MARKET,
        CORPORATE: setting.CORPORATE
      };
      formRef.current?.setFieldsValue(obj);
      setDetailData(obj);
      setCountry(data.country);
      setProvince(data.province);
      setTaxFilingOption(data.taxFilingOption);
      setPrimaryLanguage(data.primaryLanguage);


      setResidencyDetails(prevData => ({
        ...prevData,
        citizenship: data.citizenship,
        residency: data.residencyID,
        usResidency: data.usaResident
      }));

      getPostalCodeOption(data.province, data.country, '');

      if (data.taxFilingOption === '' || data.citizenship === '' || data.residencyID === '' || data.usaResident === '') {
        setIsMissingInfoOpen(true);
      }
      else {
        setIsMissingInfoOpen(false);
      }

      setPostalCode(data.zipCode);
    }
  };

  const getCountryOption = async () => {
    let params = {
      accessToken: user.accessToken,
      userId: user.userId,
    };
    const data = await get('/appUser/profile/country', params);
    if (data) {
      if (data.length > 0) {
        data.map((item, index) => {
          data[index].value = item.countryregionid;
          data[index].label = item.name;
        });
        setCountryOption(data);
      }
    }
  };
  const getProvinceOption = async () => {
    let params = {
      accessToken: user.accessToken,
      userId: user.userId,
    };
    const data = await get('/appUser/profile/province', params);
    if (data) {
      if (data.length > 0) {
        data.map((item, index) => {
          data[index].value = item.stateId;
          data[index].label = item.province;
          data[index].show = true;
        });
        setProvinceOption(data);
        setStatesOption(data.filter(x => x.countryregionid === 'USA' && x.stateId !== 'Hawaii'));
      }
    }
  };
  const getCitiOption = async () => {
    let params = {
      accessToken: user.accessToken,
      userId: user.userId,
    };
    const data = await get('/appUser/profile/citizenship', params);
    if (data) {
      if (data.length > 0) {
        data.map((item, index) => {
          data[index].value = item.citizenship;
          data[index].label = item.citizenship;
        });
        setCitiZenOption(data);
      }
    }
  };

  const getPostalCodeOption = async (province, country, postalCode) => {
    let params = {
      accessToken: user.accessToken,
      userId: user.userId,
      stateId: province,
      countryRegionId: country,
      postalCode: postalCode
    };

    const data = await get('/postal/getCodes', params);
    if (data) {
      setPostalCodeOption(data);
    }
  };

  const getTaxDisclaimer = async () => {
    let params = {
      accessToken: user.accessToken,
      userId: user.userId,
    };
    const data = await get('/appUser/profile/taxDisclaimer', params);
    if (data) {
      if (data.length > 0) {
        setTaxEngagementDisclaimer(data);
      }
    }
  };
  useEffect(() => {
    init();
    getkey();
    getCountryOption();
    getProvinceOption();
    getCitiOption();
    getLangOption();
    getTaxDisclaimer();
  }, []);


  const validatePassword = (rule, value) => {
    // 使用正则表达式验证邮箱地址
    // const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]\D|.*\D[0-9])(?=.*[\W_]\w|.*\w[\W_]).{10,20}$/;
    if (!REGEX.password.test(value)) {
      return Promise.reject(t('PasswordRules'));
    }
    return Promise.resolve();
  };
  useEffect(() => {
    if (country) {
      let options = [...provinceOption];
      options.map((item) => {
        item.show = false;
        if (item.countryregionid == country) {
          item.show = true;
        }
      });
      setProvinceOption(options);
    }
  }, [country]);

  const submitProfile = async (detail) => {
    setLoading(true)
    const country = countryOption.find(item => item?.value == detail?.country)?.countryregionid || detail?.country;
    let params = {
      ...detail,
      country,
      accessToken: user.accessToken,
      userId: user.userId,
    };
    const data = await post('/appUser/profile/request', params);
    if (data) {
      setLoading(false)
      setIsModalOpen(true);
    }
  };
  const getSetting = async () => {
    let params = {
      accessToken: user.accessToken,
      userId: user.userId,
    };
    const data = await get('/appUser/push/setting', params);
    if (data) {
      return data;
    }
  };
  const [langOption, setLangOption] = useState([])
  const getLangOption = async () => {
    let params = {
      accessToken: user.accessToken,
      userId: user.userId,
    };
    const data = await get('/option/primaryLanguage', params);
    if (data) {
      let option = [];
      data?.payload?.map((item) => {
        option.push({ value: item.code, label: item.name });
      });
      setLangOption(option);
    }
  };
  const updateSetting = async (name, value) => {

    let params = {
      onOff: value,
      type: name,
      accessToken: user.accessToken,
      userId: user.userId,
    };
    const data = await put('/appUser/push/setting', params);
    if (data) {
      return data;
    }
  };
  const submitChangePassword = async (detail) => {
    let params = {
      newPwd: encode(detail.newPwd),
      accessToken: user.accessToken,
      userId: user.userId,
    };
    const data = await put('/appUser/changePassword', params);
    if (data) {
      messageApi.open({
        type: 'success',
        content: 'Change Password Success ',
      });
    }
  };

  const ResetPassword = () => {
    return (
      <>
        <Form ref={resetFormRef} autoComplete='off'>
          <div className="headline">
            <p>{t('Change password')}</p>
          </div>

          <div className="tabsContent">
            <div className="address col-sm-6 password-section">
              <div className="list-box">
                <div className="list">
                  <p className='flex-1'>{t('New Password')}</p>
                  <FormItem
                    name="newPwd"
                    rules={[
                      { required: false, message: t('This field is required') },
                      { validator: validatePassword },
                    ]}
                    className='flex-1'
                  >
                    <CustomInputPwd />
                  </FormItem>
                </div>
                {/* <div className="list">
                  <p className="title"></p>
                  <p className="col-sm-6">
                    {t('*Minimum length of 10')}<br></br>
                    {t('*Must contain at least 2 uppercase, lowercase, numeric or special characters')}
                  </p>
                </div> */}
                <div className="list">
                  <p>{t('Confirm Password')}</p>
                  <FormItem
                    name="comPwd"
                    rules={[
                      { required: true, message: t('This field is required') },
                      {
                        validator(_, value) {
                          let newPwd = resetFormRef?.current.getFieldValue('newPwd');
                          if (!value || (value && value == newPwd)) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('Confirm Passwords not same!'));
                        },
                      },
                    ]}
                  >
                    <CustomInputPwd />
                  </FormItem>
                </div>
              </div>
            </div>
          </div>
        </Form>
        <CustomResetSubmitButton
          formRef={resetFormRef}
          onClick={() => submitChangePassword}
          text={t('Update')}
        />
      </>
    );
  };
  const SubmitSuccessPending = ({ text }) => {
    return (
      <div className="pending-box">
        <div className="left-icon">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.7491 9.70957V9.00497C18.7491 5.13623 15.7274 2 12 2C8.27256 2 5.25087 5.13623 5.25087 9.00497V9.70957C5.25087 10.5552 5.00972 11.3818 4.5578 12.0854L3.45036 13.8095C2.43882 15.3843 3.21105 17.5249 4.97036 18.0229C9.57274 19.3257 14.4273 19.3257 19.0296 18.0229C20.789 17.5249 21.5612 15.3843 20.5496 13.8095L19.4422 12.0854C18.9903 11.3818 18.7491 10.5552 18.7491 9.70957Z" stroke="white" stroke-width="1.5" />
            <path d="M7.5 19C8.15503 20.7478 9.92246 22 12 22C14.0775 22 15.845 20.7478 16.5 19" stroke="white" stroke-width="1.5" stroke-linecap="round" />
          </svg>
        </div>
        <div className="right-text">{text}</div>
      </div>
    )
  }
  const handleOk = () => {
    setIsModalOpen(false);
    init();
  };
  return (
    <div className="profile">
      {loading ? <Loading /> : null}
      <SubmitSuccessPopUp isModalOpen={isModalOpen} title={t('submissionSuccessful')} content={t('Successfully updated your profile')} handleOk={handleOk} handleCancel={handleOk} icon={'submit-success'} />
      {contextHolder}
      <div className="main-box">
        <div className='row profile-exit-banner'>
          <ExitBanner isMissingProfileBannerOpen={isMissinInfoOpen} isUpdateProfileButtonVisible={false} />
        </div>
        <div className="headline profile-wrapper">
          <div className='col-md-4 profile-title'><p>{t('Profile')}</p></div>
          <div className='col-md-8 profile-header'></div>
        </div>
        <div className="statement-box">
          <div className='row col-md-12 profile-details'>
            <div className='col-md-4'>
              <span>{user.sfUserType == 'CL' ? t('Client ID') : t('Vendor ID')}</span>
              <p>{detailData.clientId}</p>
            </div>
            <div className='col-md-4'>
              <span>{t('Username')}</span>
              <p>{detailData.username}</p>
            </div>
            {
              user.sfUserType === 'CL' ? (<>
                <div className='col-md-4'>
                  <span>{t('Date of Birth')}</span>
                  <p>{detailData.dob}</p>
                </div>
              </>) : ""
            }
          </div>
          {
            detailData.wigVendTypeId != 'IFA' ? (<>
              <div className='row col-md-12 profile-details'>
                <div className='col-md-4'>
                  <span>{t('First Name')}</span>
                  <p>{detailData.firstName}</p>
                </div>
                <div className='col-md-4'>
                  <span>{t('Middle Name')}</span>
                  <p>{detailData.middleName}</p>
                </div>
                <div className='col-md-4'>
                  <span>{t('Last Name')}</span>
                  <p>{detailData.lastName}</p>
                </div>
              </div></>) : (<></>)
          }
          {
            user.sfUserType === 'CL' ? (<>
              <div className='row col-md-12 profile-details'>
                <div className='col-md-4'>
                  <span>{t('ITN')}</span>
                  <p>{detailData.itn}</p>
                </div>
              </div>
            </>) : ""
          }
        </div>
        {isButton ? (
          <div>
            {/* {
              detailData.latestUpdatedStatus === 0 &&
              <SubmitSuccessPending text={t("SubmitProfilePending")}></SubmitSuccessPending>
            } */}
            <div className="headline">
              <p>{t('Edit Profile')}</p>
            </div>
            <div className="tabsContent">
              <Form autoComplete="off" ref={formRef} onFinish={submitProfile} scrollToFirstError>
                <div className='row col-md-12 profile-details'>
                  {user.sfUserType !== 'INTERNAL' && <p className="title">{t('General Information')}</p>}
                  <div style={{ marginBottom: "30px" }}></div>
                  {
                    user.sfUserType !== 'INTERNAL' ? (<>
                      <div className='col-md-6 form-input'>
                        <span>{t('Mobile Phone')}</span>
                        <div className='form-input-phone'>
                          <FormItem
                            name="phone"
                            rules={[{ required: true, message: t('This field is required') }]}
                          >
                            <CustomPhoneNumber inputRequired maxLength={15} />
                          </FormItem>
                        </div>
                      </div>
                    </>) : (<></>)
                  }
                  {
                    (user.sfUserType !== 'CL' && user.sfUserType !== 'INTERNAL') &&
                      (detailData.wigVendTypeId === 'Planner' || detailData.wigVendTypeId === 'Broker' ||
                        user.sfUserType === 'ADVISOR' || detailData.wigVendTypeId === 'SubRefComp' ||
                        detailData.wigVendTypeId === 'IFA' || detailData.wigVendTypeId === 'SELLER') ? (<>
                          <div className='col-md-6 form-input'>
                            <span>{t('Business Phone')}</span>
                            <div className='form-input-phone'>
                              <FormItem
                                name="businessPhone"
                                rules={[{ required: false, message: t('This field is required') }]}
                              >
                                <CustomPhoneNumber maxLength={15} />
                              </FormItem>
                            </div>
                          </div>
                        </>) : (<></>)
                  }
                  {
                    user.sfUserType === 'CL' ? (<>
                      <div className='col-md-6 form-input'>
                        <span>{t('Citizenship')}</span>
                        <FormItem
                          name="citizenship"
                          rules={[{ required: false, message: t('This field is required') }]}
                        >
                          <CustomSelect option={citiZenOption} name={"citizenship"} />
                        </FormItem>
                      </div>
                    </>) : (<></>)
                  }
                </div>
                <div className='row col-md-12 profile-details'>
                  {
                    user.sfUserType !== 'INTERNAL' ? (<>
                      <div className='col-md-6 form-input'>
                        <span>{t('Email Address')}</span>
                        <FormItem
                          name="email"
                          rules={[{ required: true, type: 'email', message: t('invalid email address') }]}
                        >
                          <CustomInput />
                        </FormItem>
                      </div>
                    </>) : (<></>)
                  }
                  {
                    user.sfUserType === 'CL' ? (<>
                      <div className='col-md-6 form-input'>
                        <span>{t('Residency')}</span>
                        <FormItem
                          name="residencyID"
                          rules={[{ required: false, message: t('This field is required') }]}
                        >
                          <CustomSelect option={citiZenOption} name={'residency'} />
                        </FormItem>
                      </div>
                    </>) : (<></>)
                  }
                </div>
                <div className='row col-md-12 profile-details'>
                  {
                    user.sfUserType === 'CL' ? (<>
                      <div className='col-md-6 form-input'>
                        <span>{t("Language")}</span>
                        <FormItem
                          name="primaryLanguage"
                          rules={[{ required: true, message: t('This field is required') }]}
                        >
                          <CustomSelect option={langOption} name={'language'} />
                        </FormItem>
                      </div>
                    </>) : (<></>)
                  }
                  {
                    user.sfUserType === 'CL' ? (<>
                      <div className='col-md-6 form-input'>
                        <span>{t("US Tax Residency")}</span>
                        <FormItem name="usaResident"
                          rules={[{ required: false, message: t('This field is required') }]}>
                          <CustomSelect option={usTaxResidencyOption} name='usTaxResidency' />
                        </FormItem>
                      </div>
                    </>) : (<></>)
                  }
                </div>
                <div className='row col-md-12 profile-details '>
                  <div className='col-md-6'>
                  </div>
                  {
                    residencyStatus ? (<>
                      <div className='col-md-6 col form-input'>
                        <span>{t("US Tax Residency (State)")}</span>
                        <FormItem
                          name="usaResidentState"
                          rules={[{ required: true, message: t('This field is required') }]}
                        >
                          <CustomSelect option={statesOption} name={'state'} />
                        </FormItem>
                      </div>
                    </>) : null
                  }
                </div>
                {user.sfUserType == 'CL' ? (
                  <div>
                    <div className="divider"></div>
                    <div className="address col-sm-8">
                      <p className="title">{t('Mailing Address')}</p>
                      <div className="hint d-flex">
                        <div className="svg-box">
                          <svg
                            className="svg-circle"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M10 20C4.48674 20 0 15.5054 0 9.97817C0 4.45098 4.48674 0 10 0C15.5133 0 20 4.47756 20 9.97817C20 15.4788 15.5133 20 10 20ZM10 1.84493C5.50189 1.84493 1.84091 5.49302 1.84091 9.97817C1.84091 14.4633 5.50189 18.1551 10 18.1551C14.4981 18.1551 18.1591 14.488 18.1591 9.97817C18.1591 5.46835 14.5 1.84493 10 1.84493Z"
                              fill="#003448"
                            />
                          </svg>
                          <svg
                            className="svg-exclamation"
                            xmlns="http://www.w3.org/2000/svg"
                            width="2"
                            height="10"
                            viewBox="0 0 2 10"
                            fill="none"
                          >
                            <path
                              d="M0.918557 9.50633C0.412028 9.50633 0 9.09255 0 8.58387V4.72129C0 4.21261 0.412028 3.79883 0.918557 3.79883C1.42509 3.79883 1.83711 4.21261 1.83711 4.72129V8.58387C1.83711 9.09255 1.42509 9.50633 0.918557 9.50633Z"
                              fill="#003448"
                            />
                            <path
                              d="M0.918557 2.89808C0.412028 2.89808 0 2.4843 0 1.97561V1.41758C0 0.908897 0.412028 0.495117 0.918557 0.495117C1.42509 0.495117 1.83711 0.908897 1.83711 1.41758V1.97561C1.83711 2.4843 1.42509 2.89808 0.918557 2.89808Z"
                              fill="#003448"
                            />
                          </svg>
                        </div>
                        {/* <p>{t('Our team may contact you to verify and update your correspondance address')}</p> */}
                        <p dangerouslySetInnerHTML={{ __html: t('AddressHint') }}></p>
                      </div>
                      <div className="list-box col-sm-9">
                        <div className="list">
                          <p>{t('Line 1')}</p>
                          <FormItem
                            name="addressLine1"
                            rules={[{ required: true, message: t('This field is required') }]}
                          >
                            <CustomInput />
                          </FormItem>
                        </div>
                        <div className="list">
                          <p>{t('Line 2')}</p>
                          <FormItem
                            name="addressLine2"
                            rules={[{ required: false, message: t('This field is required') }]}
                          >
                            <CustomInput />
                          </FormItem>
                        </div>
                        <div className="list">
                          <p>{t('Line 3')}</p>
                          <FormItem
                            name="addressLine3"
                            rules={[{ required: false, message: t('This field is required') }]}
                          >
                            <CustomInput />
                          </FormItem>
                        </div>
                        <div className="list">
                          <p>{t('City')}</p>
                          <FormItem
                            name="city"
                            rules={[{ required: false, message: t('This field is required') }]}
                          >
                            <CustomInput />
                          </FormItem>
                        </div>
                        <div className="list">
                          <p>{t('Country')}</p>
                          <div className="select-normal">
                            <FormItem
                              name="country"
                              rules={[{ required: true, message: t('This field is required') }]}
                            >
                              <CustomSelect option={countryOption} name={'country'} />
                            </FormItem>
                          </div>
                        </div>
                        <div className="list">
                          <p>{t('Province')}</p>
                          <div className="select-normal">
                            <FormItem
                              name="state"
                              rules={[{ required: false, message: t('This field is required') }]}
                            >
                              <CustomSelect
                                name={'state'}
                                option={provinceOption.filter((item) => item.show == true)}
                              />
                            </FormItem>
                          </div>
                        </div>
                        <div className="list">
                          <p>{t('Postal Code')}</p>
                          <FormItem
                            name="zipCode"
                            rules={[{ required: false, message: t('This field is required') }]}
                          >
                            <Dropdown drop='down' show={isPostalMenuOpen} onToggle={() => setIsPostalMenuOpen(!isPostalMenuOpen)}>
                              <Dropdown.Toggle as="input" type="text" className='postal-dropdown form-control' value={postalCode} onClick={() => setIsPostalMenuOpen(!isPostalMenuOpen)} onChange={(e) => handlePostalChange(e)} />
                              <Dropdown.Menu className='postal-dropdown-menu'>
                                {
                                  postalCodeOption?.map((postalCode) => (<>
                                    <Dropdown.Item eventKey={postalCode.postalCode} onClick={() => handlePostalClick(postalCode.postalCode)}>
                                      {postalCode.postalCode}
                                    </Dropdown.Item>
                                  </>))
                                }
                              </Dropdown.Menu>
                            </Dropdown>
                          </FormItem>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                {
                  user.sfUserType === 'CL' && (<>
                    <div className="divider"></div>
                    <div className='tax-container col-md-12'>
                      <div className='tax-title row'>
                        <p>{t('Tax')}</p>
                      </div>
                      <div className='row col-md-12'>
                        <div className='col-md-3'>
                          <span>{t('US Tax Engagement')}:</span>
                        </div>
                        <div className='col-md-4'>
                          <FormItem name="taxFilingOption"
                            rules={[{ required: true, message: t('This field is required') }]}>
                            <select className='us-tax-engagement' onChange={(e) => handleChangeTax("taxFilingOption", e)} disabled={detailData.taxFilingOption !== "" ? "disabled" : ""} >
                              <option value=''>{t('Select Engagement')}</option>
                              <option value='RSM'>{t('Engage')}</option>
                              <option value='NO RSM'>{t('Not Engage')}</option>
                            </select>
                          </FormItem>
                        </div>
                      </div>
                      {
                        taxFilingOption !== '' ? (<>
                          <div className='tax-disclaimer col-md-12 tax-svg-box' title='Tax Engagement Disclaimer'>
                            <svg
                              className="svg-circle"
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                d="M10 20C4.48674 20 0 15.5054 0 9.97817C0 4.45098 4.48674 0 10 0C15.5133 0 20 4.47756 20 9.97817C20 15.4788 15.5133 20 10 20ZM10 1.84493C5.50189 1.84493 1.84091 5.49302 1.84091 9.97817C1.84091 14.4633 5.50189 18.1551 10 18.1551C14.4981 18.1551 18.1591 14.488 18.1591 9.97817C18.1591 5.46835 14.5 1.84493 10 1.84493Z"
                                fill="#003448"
                              />
                            </svg>
                            <svg
                              className="svg-exclamation"
                              xmlns="http://www.w3.org/2000/svg"
                              width="2"
                              height="10"
                              viewBox="0 0 2 10"
                              fill="none"
                            >
                              <path
                                d="M0.918557 9.50633C0.412028 9.50633 0 9.09255 0 8.58387V4.72129C0 4.21261 0.412028 3.79883 0.918557 3.79883C1.42509 3.79883 1.83711 4.21261 1.83711 4.72129V8.58387C1.83711 9.09255 1.42509 9.50633 0.918557 9.50633Z"
                                fill="#003448"
                              />
                              <path
                                d="M0.918557 2.89808C0.412028 2.89808 0 2.4843 0 1.97561V1.41758C0 0.908897 0.412028 0.495117 0.918557 0.495117C1.42509 0.495117 1.83711 0.908897 1.83711 1.41758V1.97561C1.83711 2.4843 1.42509 2.89808 0.918557 2.89808Z"
                                fill="#003448"
                              />
                            </svg>
                            <div className='tax-disclaimer-text' dangerouslySetInnerHTML={{ __html: disclaimer }}></div>
                          </div>
                        </>) : null
                      }
                      <div className="divider"></div>
                      <div className='tax-title row col-md-12'>
                        <p>{t('Tax Identification')}</p>
                      </div>
                      <div className='row col-md-12 profile-details'>
                        <div className='col-md-4'>
                          <span>{t('ITIN')}</span>
                          <p>{detailData.itin}</p>

                        </div>
                        <div className='col-md-4'>
                          <span>{t('Expiration Date')}</span>
                          <p>{detailData.itinExpirationDate && formatDate(new Date(detailData.itinExpirationDate))}</p>
                        </div>
                        <div className='col-md-4'>
                          <span>{t('Application Status')}</span>
                          <p>{detailData.itinApplicationStatus}</p>
                        </div>
                      </div>
                    </div>
                  </>)
                }

                {
                  user.sfUserType != 'INTERNAL' && <div className="divider"></div>
                }
                <div className="list-box row settings">
                  <p className="title" style={{ fontSize: '18px' }}>
                    {t('Setting')}
                  </p>
                  <div className="col-sm-6 list d-flex setting-content">
                    <span>{t('Market Updates')} </span>
                    <div className="input-box d-flex" style={{ marginLeft: '20px' }}>
                      <FormItem
                        name="MARKET"
                        rules={[{ required: false, message: t('This field is required') }]}
                      >
                        <Switch className='custom-switch'
                          onChange={(checked, event) => {
                            updateSetting('MARKET', checked);
                          }}
                        ></Switch>
                      </FormItem>
                    </div>
                  </div>
                  <div className="col-sm-6 list d-flex setting-content">
                    <span>{t('Corporate Updates')}</span>
                    <div className="input-box d-flex" style={{ marginLeft: '20px' }}>
                      <div className="select-phone">
                        <FormItem
                          name="CORPORATE"
                          rules={[{ required: false, message: t('This field is required') }]}
                        >
                          <Switch className='custom-switch'
                            onChange={(checked, event) => {
                              updateSetting('CORPORATE', checked);
                            }}
                          ></Switch>
                        </FormItem>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
            <CustomSubmitButton
              formRef={formRef}
              onClick={() => submitProfile}
              text={t('Submit')}
            />
            {
              user.sfUserType != 'INTERNAL' && <ResetPassword></ResetPassword>
            }
          </div>
        ) : (
          <div className="tabsContent"></div>
        )
        }
      </div >
    </div >
  );
};
export default Profile;
